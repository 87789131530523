import React, { useContext } from 'react'
import classes from './ReferralData.module.css'
import { GlobalStateContext, GlobalDispatchContext } from '../../context/GlobalContextProvider'
import * as moment from 'moment'

const ReferralData = () => {

  const state = useContext(GlobalStateContext)
  // const dispatch = useContext(GlobalDispatchContext)

  // console.log(state)

  const totalCaseNumbers = state.cases.length

  return (
    <div className={classes.referralData}>
      <h5 className={classes.clientID}>ID: {state.id}</h5>

      <div>
        {
          state.cases.map((item, i) => {
            // console.log(item);

            return (
              <div className={classes.refStatus} key={i}>
                <h5 className={classes.refNumber}>Reference #: { item.case_id }</h5>

                <div className={classes.caseDetails}>
                  <div className={classes.createdTimeWrap}>
                    <p className={classes.createdTime}>Created Time: <span>{ moment.unix(item.created).format("MM/DD/YYYY") }</span></p>
                  </div>

                  <div className={classes.createdStatusWrap}>
                    {
                      item.status === 'accepted'
                        ?
                          <p className={classes.createdStatusActive}>Status: <span>{item.status}</span></p>
                        :
                          <p className={classes.createdStatus}>Status: <span>{item.status}</span></p>
                    }
                  </div>
                </div>

                <div>
                  <h5 className={classes.organization}>Organization(s)</h5>

                  {
                    item.status !== 'accepted' &&
                      <ul className={classes.organizationList}>
                      {
                        item.invited_orgs.map((org, i) => {
                          return (
                            <li key={i}><a href={org.url} target="_blank" rel="noreferrer">{org.org}</a></li>
                          )
                        })
                      }
                    </ul>
                  }

                  {
                    item.status === 'accepted' &&
                      <ul className={classes.organizationList}>
                      {
                        <li><a href={item.target_org.url} target="_blank" rel="noreferrer">{item.target_org.org}</a></li>
                      }
                    </ul>
                  }


                </div>

                {
                  i + 1 !== totalCaseNumbers
                    ?
                      <hr/>
                    :
                      null
                }

              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default ReferralData
