import React from 'react'
import classes from './Sidebar.module.css'

const InnerPageSidebar = ({ sidebarNews, currentNews, secondNews }) => {
  const newsList = sidebarNews.edges
  const size = 6;
  const regex = /(<([^>]+)>)/ig;

  const filteredNews = newsList.filter((item) => {
    return item.node.id !== currentNews && item.node.id !== secondNews && item.node.media_thumbnail?._.url !== ''
  })

  return (
    <>
      <div className={classes.innerSidebar}>
        <h2>Featured</h2>

        {
          filteredNews.slice(0, size).map((news, i) => {

            return (
              <div key={i}>
                <img
                  src={news.node.media_thumbnail?._.url ? news.node.media_thumbnail._.url : null}
                  alt={ news.node.media_description ? news.node.media_description.replace(regex, '') : news.node.title.replace(regex, '') }
                />
                <h3 dangerouslySetInnerHTML={{ __html: news.node.title }} />
              </div>
            )
          })
        }
      </div>

    </>
  )
}

export default InnerPageSidebar
