import React, { useState } from 'react'
import InnerPageLayout from "../components/inner-page-layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import ReferralData from '../components/ReferralData/ReferralData'

const ReferralPage = ({ data }) => {

  console.log(data)

  const post = data.main
  const secondary = data.secondary
  const ternary = data.ternary
  // const regex = /(<([^>]+)>)/ig

  // TODO: make this global var
  const [ loginBtnStatus, setLoginBtnStatus ] = useState(false)

  // console.log(111, post)

  return (
    <>
      <Header siteTitle={'News'} setLoginBtnStatus={setLoginBtnStatus} loginStatus={loginBtnStatus} />
      <InnerPageLayout sidebarNews={ternary} currentNews={post.id} secondNews={secondary.id}>
        <base href={`/news/${post.id}`} />
        <SEO title="Texas News Web | News" />

        <h1 dangerouslySetInnerHTML={{ __html: post.title }} />

        <img
          src={ post.media_thumbnail?._.url }
          alt={ post.media_title }
        />

        <p dangerouslySetInnerHTML={{ __html: post.description }} />

        <ReferralData />

        <img
          src={ secondary.media_thumbnail?._.url }
          alt={ secondary.media_title }
        />

        <h4 dangerouslySetInnerHTML={{ __html: secondary.title }} />

        <p dangerouslySetInnerHTML={{ __html: secondary.description }} />
      </InnerPageLayout>
      <Footer />
    </>
  )
}

export default ReferralPage

export const query = graphql`
  query($id: String!) {
    main: news(media_thumbnail: {_: {url: {ne: null}}}) {
      title
      description
      id
      link
      pubDate
      media_thumbnail {
        _ {
          url
        }
      }
      media_description
      media_title
    }
    secondary: news(id: { ne: $id }, media_thumbnail: {_: {url: {ne: null}}}) {
      title
      description
      id
      link
      pubDate
      media_thumbnail {
        _ {
          url
        }
      }
      media_description
      media_title
    }
    ternary: allNews(filter: {id: { ne: $id }, media_thumbnail: {_: {url: {ne: null}}}}) {
      edges {
        node {
          title
          description
          id
          link
          pubDate
          media_thumbnail {
            _ {
              url
            }
          }
          media_description
          media_title
        }
      }
    }
  }
`
